import "./index.scss";

import { Controller as BaseController } from "stimulus";
import { useIntersection } from "stimulus-use";
import consumer from "~/channels/consumer";
import ahoy from "ahoy.js";

export default class extends BaseController {
  static values = {
    notificationId: String,
    bannerType: String,
    bannerContent: String,
    bannerTitle: String,
    bannerId: Number,
  };

  static targets = ["content"];

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "Noticed::NotificationChannel" },
      {
        connected: this._connected.bind(this),
      }
    );
    this.visible = false;
    useIntersection(this);
  }

  disconnect() {
    this.subscription.unsubscribe();
    this.checkVisibleInterval && clearInterval(this.checkVisibleInterval);
  }

  _connected() {
    this.checkVisibleInterval = setInterval(() => this.#markAsSeen(), 5000);
  }

  hideBanner() {
    this.subscription.perform("mark_as_read", { ids: [this.notificationIdValue] });
    ahoy.track("$banner", {
      ...this.#ahoyParams(),
      action: "dismiss",
    });
    this.element.remove();
  }

  #markAsSeen() {
    if (this.visible) {
      this.subscription.perform("mark_as_seen", { ids: [this.notificationIdValue] });
      this.checkVisibleInterval && clearInterval(this.checkVisibleInterval);
    }
  }

  appear() {
    this.visible = true;
    this.#markAsSeen();
    ahoy.track("$banner", {
      ...this.#ahoyParams(),
      action: "view",
    });

    // check if link is relative and/or external, then add target="_blank"
    this.contentTarget.querySelectorAll("a").forEach((link) => {
      if (!link.href.startsWith("/") && !link.href.startsWith(window.location.origin)) {
        link.target = "_blank";
      }

      link.addEventListener("click", () => {
        ahoy.track("$banner", {
          ...this.#ahoyParams(),
          href: link.href,
          href_text: link.text,
          action: "click",
        });
      });
    });
  }

  #ahoyParams() {
    return {
      banner_id: this.bannerIdValue,
      banner_type: this.bannerTypeValue,
      banner_title: this.bannerTitleValue,
      banner_content: this.bannerContentValue,
      page: window.location.pathname,
    };
  }
}
